import React, { useState } from "react";
import styles from "./SpecialQuizCreation.module.css";
import SpecialQuizCard from "../../molecules/SpecialQuizCard/SpecialQuizCard";
import { createSpecialQuiz, uploadSpecialQuizImage } from "../../../apiHelpers";

const SpecialQuizCreation = ({ setShowQuizCreation }) => {
  // File Related States
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  // Fields for first page
  const [quizKey, setQuizKey] = useState("");
  const [startDate, setStartDate] = useState("");
  const [error, setError] = useState("");

  // Fields for second page
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [backgroundColor, setBackgroundColor] = useState(null);

  // Page tracking
  const [currentPage, setCurrentPage] = useState(1);

  // Day Tracker
  const isTuesday = (date) => new Date(date).getUTCDay() === 2;

  const getNextMonday = (startDate) => {
    const date = new Date(startDate); // Create a new Date object from the input startDate
    const dayOfWeek = date.getUTCDay(); // Get the current day of the week (0 for Sunday, 1 for Monday, etc.)

    // Calculate the number of days to add to get to the next Monday
    const daysToAdd = dayOfWeek === 1 ? 7 : 8 - dayOfWeek; // If it's Monday (1), we add 7 days, else we calculate how many days to add

    date.setUTCDate(date.getUTCDate() + daysToAdd); // Add the days to the current date
    return date.toISOString().split("T")[0]; // Return the new date in YYYY-MM-DD format
  };

  // Handler Function
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      // Generate imagePreview using FileReader
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const createQuizHandler = () => {
    if (!isFirstPageValid || !isSecondPageValid) {
      setError("Please ensure all fields are valid before proceeding.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    uploadSpecialQuizImage(formData).then((response) => {
      if (response.fileLocation) {
        setError("");
        const quizPayload = {
          key: quizKey.trim(), // Trim spaces from quizKey
          startingAt: startDate.trim() + "T00:00:00.000Z", // Assuming date fields are string and need trimming (for safety)
          endingAt: getNextMonday(startDate) + "T23:59:59.000Z", // Get the next Monday and add the time
          name: name.trim(), // Trim spaces from name
          description: description.trim(), // Trim spaces from description
          backgroundColor: {
            backgroundColorStart: backgroundColor,
            backgroundColorEnd: getDarkerColor(backgroundColor),
          },
          backgroundImage: response.fileLocation.trim(), // Trim spaces from imageUrl
        };
        createSpecialQuiz(quizPayload).then((response) => {
          if (response._id) {
            alert("Special Quiz created successfully!");
            setShowQuizCreation(false);
          } else {
            alert("Special Quiz creation failed!");
            // Reseting all states of the form
            setQuizKey("");
            setStartDate("");
            setError("");
            setName("");
            setDescription("");
            setBackgroundColor("");
            setCurrentPage(1);
          }
        });
      } else {
        alert("Image upload failed!");
      }
    });
  };

  const handleNextPage = () => {
    if (isFirstPageValid) {
      setCurrentPage(2);
    } else {
      setError("Please ensure all fields are valid before proceeding.");
    }
  };

  // Validation checks
  const isFirstPageValid =
    quizKey && startDate && isTuesday(startDate) && !error;

  const isSecondPageValid = name && backgroundColor && imagePreview;

  // Color Functions
  const hexToHSL = (hex) => {
    hex = hex.replace(/^#/, "");
    let r = parseInt(hex.substring(0, 2), 16) / 255;
    let g = parseInt(hex.substring(2, 4), 16) / 255;
    let b = parseInt(hex.substring(4, 6), 16) / 255;

    let max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      let d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
      }
      h = Math.round(h * 60);
    }

    return { h, s, l };
  };

  const hslToHex = (h, s, l) => {
    s /= 100;
    l /= 100;

    let c = (1 - Math.abs(2 * l - 1)) * s;
    let x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    let m = l - c / 2;
    let r, g, b;

    if (h < 60) {
      r = c;
      g = x;
      b = 0;
    } else if (h < 120) {
      r = x;
      g = c;
      b = 0;
    } else if (h < 180) {
      r = 0;
      g = c;
      b = x;
    } else if (h < 240) {
      r = 0;
      g = x;
      b = c;
    } else if (h < 300) {
      r = x;
      g = 0;
      b = c;
    } else {
      r = c;
      g = 0;
      b = x;
    }

    r = Math.round((r + m) * 255)
      .toString(16)
      .padStart(2, "0");
    g = Math.round((g + m) * 255)
      .toString(16)
      .padStart(2, "0");
    b = Math.round((b + m) * 255)
      .toString(16)
      .padStart(2, "0");

    return `#${r}${g}${b}`;
  };

  const getDarkerColor = (color1) => {
    let { h, s, l } = hexToHSL(color1);
    let darkerL = Math.max(l - 25, 30); // Reduce lightness
    let mutedS = Math.max(s - 5, 20); // Reduce saturation slightly

    return hslToHex(h, mutedS, darkerL);
  };

  return (
    <div className={styles.SpecialQuizCreation}>
      <button
        className={styles.BackButton}
        onClick={() => setShowQuizCreation(false)}
      >
        Back
      </button>
      {currentPage === 1 && (
        <>
          <h2>Create Special Quiz</h2>
          <p>Enter the creation key below:</p>
          <input
            type="password"
            placeholder="Enter the creation key"
            className={styles.inputField}
            value={quizKey}
            onChange={(e) => setQuizKey(e.target.value)}
          />
          <label className={styles.dateLabel}>Start Date (Tuesday)</label>
          <input
            type="date"
            className={styles.inputField}
            value={startDate}
            onChange={(e) => {
              const selectedDate = e.target.value;
              if (!isTuesday(selectedDate)) {
                setStartDate("");
                setError("Start date must be a Tuesday!");
              } else {
                setError("");
                setStartDate(selectedDate);
              }
            }}
          />
          {error && <p className={styles.errorText}>{error}</p>}
          <button
            className={styles.createButton}
            onClick={handleNextPage}
            disabled={!isFirstPageValid}
          >
            Next
          </button>
        </>
      )}
      {currentPage === 2 && (
        <>
          <h2>Special Quiz Details</h2>
          <p>Enter the name below:</p>
          <input
            type="input"
            placeholder="Enter the name"
            className={styles.inputField}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {/* <p>Enter the description below:</p>
          <input
            type="input"
            placeholder="Enter the description"
            className={styles.inputField}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          /> */}
          <div>
            <p>Upload the Image</p>
            <input
              type="file"
              accept="image/png,image/jpeg,image/jpg"
              onChange={handleFileChange}
            />
            {imagePreview && (
              <div>
                <p>Selected Image:</p>
                <img src={imagePreview} alt="Selected" width="200" />
              </div>
            )}
          </div>
          <p>Select the background color </p>
          <div className={styles.selectContainer + " " + styles.colorpicker}>
            <input
              type="color"
              value={backgroundColor}
              onChange={(e) => {
                setBackgroundColor(e.target.value);
              }}
            />
          </div>
          {isSecondPageValid && (
            <SpecialQuizCard
              id={quizKey}
              name={name}
              imageName1={imagePreview}
              imagePreview={true} // Boolean to NOT show the play button in the backOffice
              backgroundColor={{
                backgroundColorStart: backgroundColor,
                backgroundColorEnd: getDarkerColor(backgroundColor),
              }}
              textQuestionsPrize={description}
              endingAt={getNextMonday(startDate)}
            />
          )}
          <button
            className={styles.createButton}
            onClick={createQuizHandler}
            disabled={!isSecondPageValid}
          >
            Create Special Quiz
          </button>
        </>
      )}
    </div>
  );
};

export default SpecialQuizCreation;
