import React, { useState } from "react";
import styles from "./TournamentCreation.module.css"; // Separate CSS for styling
import {
  createTournament,
  refillTournamentSubscribers,
} from "../../../apiHelpers";

const TournamentCreation = ({ showCreation, setShowCreation }) => {
  const [tournamentKey, setTournamentKey] = useState(""); // Stores the tournament key
  const [key, setKey] = useState(""); // Stores the minor key
  const [date, setDate] = useState(""); // Stores the date in "Y-M-D" format
  const [day, setDay] = useState(""); // Automatically computed day of the week

  const tournamentData = {
    Sunday: {
      name: "SUNDAY",
      backgroundImage: "einstein1",
      description: "15 QUESTIONS 200€ PRIZE",
      backgroundColor: "blueBackground",
      amount: 200,
      cost: 3,
    },
    Monday: {
      name: "MONDAY",
      backgroundImage: "einstein2",
      description: "10 QUESTIONS 50€ PRIZE",
      backgroundColor: "lightPurpleBackground",
      amount: 50,
      cost: 1,
    },
    Wednesday: {
      name: "WEDNESDAY",
      backgroundImage: "einstein4",
      description: "10 QUESTIONS 50€ PRIZE",
      backgroundColor: "darkPurpleBackground",
      amount: 50,
      cost: 1,
    },
    Friday: {
      name: "FRIDAY",
      backgroundImage: "einstein6",
      description: "10 QUESTIONS 50€ PRIZE",
      backgroundColor: "redBackground",
      amount: 50,
      cost: 1,
    },
  };

  const validDays = ["Monday", "Wednesday", "Friday", "Sunday"];

  // Compute the day of the week based on the entered date
  const getDayOfWeek = (date) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const parsedDate = new Date(date); // Convert string to Date object
    return daysOfWeek[parsedDate.getUTCDay()]; // Get day of the week
  };

  const createTournamentHandler = () => {
    const selectedTournament = tournamentData[day];
    if (!selectedTournament) {
      alert("The selected day does not have a tournament!");
      return;
    }

    const isDST = (dateString) => {
      const date = new Date(dateString + "T00:00:00"); // Local date at midnight
      const januaryOffset = new Date(
        date.getFullYear(),
        0,
        1
      ).getTimezoneOffset();
      const julyOffset = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
      return date.getTimezoneOffset() < Math.max(januaryOffset, julyOffset);
    };

    const adjustedHour = isDST(date) ? "17:00:00.000Z" : "18:00:00.000Z";
    const startingAt = date + `T${adjustedHour}`;

    const tournamentPayload = {
      ...selectedTournament,
      key: tournamentKey,
      startingAt,
    };

    createTournament(tournamentPayload).then((response) => {
      //   console.log(response._id);
      refillTournamentSubscribers(response._id, key).then((response) => {
        // console.log(response);
        if (response.message) {
          alert("Tournament created successfully!");
          setShowCreation(false);
        }
      });
    });
  };

  // Validation
  const isValidDate = (date) => /^\d{4}-\d{2}-\d{2}$/.test(date); // Validate "YYYY-MM-DD" format
  const isDayValid = validDays.includes(day); // Check if the computed day is valid
  const isFormValid =
    tournamentKey && key && date && isValidDate(date) && isDayValid;

  return (
    <div className={styles.TournamentCreationPage}>
      <button
        className={styles.BackButton}
        onClick={() => setShowCreation(false)}
      >
        Back
      </button>
      <h2>Create Tournament</h2>
      <p>Enter the generic key below:</p>
      <input
        type="password"
        placeholder="Enter the generic key"
        className={styles.inputField}
        value={key}
        onChange={(e) => setKey(e.target.value)} // Update key state
      />
      <p>Enter the creation key below:</p>
      <input
        type="password"
        placeholder="Enter the creation key"
        className={styles.inputField}
        value={tournamentKey}
        onChange={(e) => setTournamentKey(e.target.value)} // Update tournament key state
      />
      <br />
      <input
        type="date" // Use a date picker for better user experience
        className={styles.inputField}
        value={date}
        onChange={(e) => {
          setDate(e.target.value); // Update date state
          const computedDay = getDayOfWeek(e.target.value);
          setDay(computedDay); // Automatically set the day
        }}
      />
      <br />
      {day && (
        <p
          style={{
            color: isDayValid ? "green" : "red", // Dynamic styling: Green for valid, Red for invalid
            fontWeight: "bold",
          }}
        >
          Selected Day: {day} {isDayValid ? "" : "(Invalid - No Tournament)"}
        </p>
      )}
      <button
        className={styles.createButton}
        onClick={createTournamentHandler}
        disabled={!isFormValid} // Disable if form is not valid
      >
        Create Tournament
      </button>
    </div>
  );
};

export default TournamentCreation;
