import React, { useState } from "react";
import defaultIcon from "../../../assets/images/special_quizzes/special1.png";

const LinkIcon = ({additionalClass = "", imageName1}) => {
    const [imageError, setImageError] = useState(false);
    
  return (
    <div>
      <img
        className={additionalClass}
        src={imageError ? defaultIcon : imageName1}
        onError={() => setImageError(true)} // Triggered if the image fails to load
      />
    </div>
  );
};

export default LinkIcon;