import React, { useEffect, useRef, useState } from "react";
import Typography from "../../atoms/Typography/Typography";
import Button from "../../atoms/Button/Button";
import TournamentCardButton from "../TournamentCardButton/TournamentCardButton";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../AthContext";
import { gsap } from "gsap";
import styles from "./SpecialQuizCard.module.css";
import LinkIcon from "../../atoms/LinkIcon/LinkIcon";

const SpecialQuizCard = ({
  id,
  imageName1,
  textQuestionsPrize,
  backgroundColor,
  name,
  endingAt,
  isSpecialQuizCompleted,
  imagePreview = false,
}) => {
  const { isMobile, loggedIn } = useAuth();
  const navigate = useNavigate();
  const cardRef = useRef(null);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const observerRef = useRef(null);
  const currentDate = new Date().getTime();
  const quizStartDate = new Date(endingAt).getTime();
  const specialQuizSecondsToStart = quizStartDate - currentDate;

  // Validate and apply dynamic background colors
  const getBackgroundStyle = () => {
    if (
      backgroundColor &&
      typeof backgroundColor === "object" &&
      backgroundColor.backgroundColorStart &&
      backgroundColor.backgroundColorEnd &&
      /^#([0-9A-F]{3}){1,2}$/i.test(backgroundColor.backgroundColorStart) &&
      /^#([0-9A-F]{3}){1,2}$/i.test(backgroundColor.backgroundColorEnd)
    ) {
      return {
        background: `linear-gradient(180deg, ${backgroundColor.backgroundColorStart} 60%, ${backgroundColor.backgroundColorEnd} 99%)`,
      };
    }
    // Default gradient if invalid colors are received
    return {
      background: "linear-gradient(180deg, #ff6262 60%, #803232 99%)",
    };
  };

  // Animation function
  const startAnimation = () => {
    const timeline = gsap.timeline();

    // Slide in and immediately start squeezing
    timeline
      .fromTo(
        cardRef.current,
        { x: 400, scaleX: 1, scaleY: 1 },
        {
          x: 0,
          duration: 0.4,
          ease: "power4.out",
        }
      )
      .to(
        cardRef.current,
        {
          scaleX: 0.7,
          duration: 0.2,
          transformOrigin: "left center",
          ease: "power2.out",
        },
        "<0.1"
      ) // Start squeezing just before the slide ends
      .to(cardRef.current, {
        scaleX: 1,
        duration: 0.3,
        ease: "power2.out",
      });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Only start animation when the area is just below the viewport
            setShouldAnimate(true);
          } else {
            // Reset the animation when the card goes out of the viewport
            setShouldAnimate(false);
          }
        });
      },
      {
        threshold: 0.2, // Trigger as soon as any part of the area is visible
        rootMargin: "50px 0px", // Trigger when the area is 100px below the viewport
      }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    // Cleanup
    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, []);

  // Trigger animation when shouldAnimate changes
  useEffect(() => {
    if (shouldAnimate) {
      startAnimation();
    } else {
      // Reset the card to its initial state when it leaves the viewport
      gsap.set(cardRef.current, { x: 400, scaleX: 1, scaleY: 1 });
    }
  }, [shouldAnimate]);

  return (
    <div ref={observerRef} className={styles.renderArea}>
      <div
        className={`${styles.cardContainer}`}
        ref={cardRef}
        style={{ ...getBackgroundStyle(), opacity: shouldAnimate ? 1 : 0 }}
      >
        <LinkIcon additionalClass={styles.icon} imageName1={imageName1} />
        <div className={styles.quizInfo}>
          <div className={styles.quizDateContainer}>
            <Typography
              variant={isMobile ? "caption" : "body1"}
              fontWeight={"font400"}
              additionalClass={styles.quizDate}
            >
              {endingAt &&
                `End Date: ${endingAt.substring(8, 10)}/${endingAt.substring(
                  5,
                  7
                )}/${endingAt.substring(2, 4)}`}
            </Typography>
          </div>
          <Typography
            variant={isMobile ? "body2" : "heading2"}
            fontWeight={"font900"}
            additionalClass={styles.quizName}
          >
            {name}
          </Typography>
          <Typography
            variant={isMobile ? "caption" : "heading3"}
            fontWeight={"font400"}
            additionalClass={styles.quizDescription}
          >
            {textQuestionsPrize}
          </Typography>
        </div>
        <div className={styles.cardBottom}>
          {!loggedIn && (
            <Button
              additionalClass={styles.registerGoldButton}
              onClick={() => {
                navigate("/forms");
              }}
            >
              <Typography
                variant={isMobile ? "caption" : "body1"}
                color="colorBlack"
              >
                {"Register Now"}
              </Typography>
            </Button>
          )}

          {loggedIn && !imagePreview && (
            <TournamentCardButton
              specialQuizId={id}
              isSpecialQuiz={true}
              specialQuizSecondsToStart={specialQuizSecondsToStart}
              isSpecialQuizCompleted={isSpecialQuizCompleted}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SpecialQuizCard;
