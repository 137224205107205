import React, { useState, useEffect, useCallback } from "react";
import * as XLSX from "xlsx"; // Import SheetJS
import styles from "./SpecialQuizManagement.module.css"; // CSS for styling
import {
  getSpecialQuizQuestions,
  deleteSpecialQuizQuestions,
  setSpecialQuizQuestions,
  setSpecialQuizQuestionsUnfactored,
} from "../../../apiHelpers";

const SpecialQuizManagement = ({
  specialQuizTitle,
  specialQuizId,
  backFunction,
  questionsNum,
  updaterFunction,
}) => {
  const [key, setKey] = useState(""); // Stores the entered key
  const [questions, setQuestions] = useState(""); // Stores questions JSON
  const [loading, setLoading] = useState(true);

  // Fetch existing questions
  const fetchQuestions = useCallback(async () => {
    try {
      const questions = await getSpecialQuizQuestions(specialQuizId, 1);
      const sanitizedQuestions = questions.data.map(
        ({ _id, specialQuizId, ...rest }) => rest
      );
      setQuestions(
        sanitizedQuestions.length
          ? JSON.stringify(sanitizedQuestions, null, 2)
          : ""
      );
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch questions:", error);
    }
  }, [specialQuizId]);

  useEffect(() => {
    fetchQuestions();
  }, [fetchQuestions]);

  // Validate form: Requires key + either JSON input or uploaded Excel data
  const isFormValid = key && (questions || !questionsNum);

  // Handle file upload & convert Excel to JSON
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Get first sheet name & sheet data
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        // Convert worksheet to JSON
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        // Delete Previous Questions
        await deleteSpecialQuizQuestions(specialQuizId, key);

        // Set New Questions using the Upload API
        await setSpecialQuizQuestionsUnfactored(specialQuizId, key, jsonData);

        alert("Questions added successfully!");

        // Trigger any state updates or UI changes
        updaterFunction();
        backFunction();
      } catch (error) {
        console.error("Error during file upload or processing:", error);
        alert("There was an error processing the file. Please try again.");
      }
    };

    // Start reading the file as an array buffer
    reader.readAsArrayBuffer(file);
  };

  // Submit questions
  const addQuestionsHandler = async () => {
    try {
      const questionsJson = JSON.parse(questions);
      await deleteSpecialQuizQuestions(specialQuizId, key);
      await setSpecialQuizQuestions(specialQuizId, key, questionsJson);
      alert("Questions added successfully!");
      updaterFunction();
      backFunction();
    } catch (error) {
      console.error("Failed to add questions:", error);
      alert("Failed to add questions!");
    }
  };

  if (!specialQuizTitle || !specialQuizId || loading) {
    return (
      <div>
        <p>Loading...</p>
        <button className={styles.BackButton} onClick={backFunction}>
          Back
        </button>
      </div>
    );
  }

  return (
    <div className={styles.SpecialQuizManagement}>
      <button className={styles.BackButton} onClick={backFunction}>
        Back
      </button>
      <h1>{specialQuizTitle}</h1>

      {questionsNum ? (
        <div className={styles.error}>
          This Special Quiz already has questions.
        </div>
      ) : null}

      <p>Enter the generic key:</p>
      <input
        type="password"
        placeholder="Enter generic key"
        className={styles.inputField}
        value={key}
        onChange={(e) => setKey(e.target.value)}
      />
      {questionsNum ? (
        <>
          <p>Enter Questions Object in JSON format:</p>
          <textarea
            placeholder="Paste your JSON here"
            className={styles.textareaField}
            value={questions}
            onChange={(e) => setQuestions(e.target.value)}
            rows={20}
            cols={80}
          />
          <button
            className={styles.addButton}
            onClick={addQuestionsHandler}
            disabled={!isFormValid}
          >
            Update Questions
          </button>
        </>
      ) : (
        <></>
      )}

      <>
        <p>
          Upload an Excel file (.xlsx): (This action deletes the previous
          questions)
        </p>
        <input
          type="file"
          accept=".xlsx"
          onChange={handleFileUpload}
          disabled={!key}
        />
      </>
    </div>
  );
};

export default SpecialQuizManagement;
